<template>
  <v-container>
    <Datatable ref="externalAPPSDataTable" no-excel-export :show-select-checkbox="false"
               :api-endpoint="ENDPOINTS.DATATABLES.MODULESETTINGS.EXTERNALAPPS"
               show-edit-buttons
               show-delete-buttons
               :datatable-headers="dataTableHeaders"
               @editEntry="editEntry"
               @deleteEntry="deleteEntry"
               :permission-edit="$store.getters['permissions/checkPermission']('edit_external_apps')"
               :permission-delete="$store.getters['permissions/checkPermission']('delete_external_apps')"
               @deleteEntries="deleteData">
    </Datatable>
  </v-container>
</template>

<script>
import {ENDPOINTS} from "../../../../config";
import {Events} from "../../../../plugins/events";
import Datatable from "../../../datatable/Datatable";

export default {
  name: "ExternalAPPSComponent",
  components: {Datatable},
  data() {
    return {
      ENDPOINTS,
      uuid: null,
    }
  },
  computed: {
    dataTableHeaders() {
      return [
        {
          text: "ID",
          value: "id",
          sortable: false,
          hide: true,
        },
        {
          text: this.$t('generic.lang_name'),
          value: "name",
        },
      ]
    },
  },
  methods: {
    deleteData: function (idsToDelete = []) {
      let self = this;
      this.$swal({
        title: this.$t('settings.lang_deleteExternalApp'),
        text: this.$t('settings.lang_deleteExternalAppText'),
        cancelButtonText: this.$t('generic.lang_cancel'),
        confirmButtonText: this.$t('generic.lang_delete'),
        icon: "warning",
        showCancelButton: true,
        showLoaderOnConfirm: true,
        preConfirm: () => {
          if (!idsToDelete || !idsToDelete.length || idsToDelete.length === 0) {
            idsToDelete = [];
            idsToDelete.push(this.uuid);
          }
          this.axios.post(ENDPOINTS.SETTINGS.MODULESETTINGS.EXTERNALAPPS.DELETE, {
            externalAppsIDs: idsToDelete,
          }).then((res) => {
            if (res.data.status === 'SUCCESS') {
              Events.$emit("showSnackbar", {
                message: this.$t('generic.lang_actionSuccessful'),
                color: "success"
              });
              self.$refs.externalAPPSDataTable.getDataFromApi();
              self.$refs.externalAPPSDataTable.resetSelectedRows();
            } else {
              Events.$emit("showSnackbar", {
                message: this.$t('generic.lang_errorOccurred'),
                color: "error"
              });
            }
          });
        },
        allowOutsideClick: () => !this.$swal.isLoading,
      });
    },

    deleteEntry(entry) {
      this.uuid = entry.id;
      this.deleteData();
    },
    editEntry(entry){
      this.$router.push({name: 'settings.moduleSettings.externalApps.edit', params: {uuid: entry.id}})
    }
  },
  mounted() {

  }


}
</script>

<style scoped>

</style>