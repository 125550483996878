<template>
    <div>
        <page-title :heading="$t('settings.lang_externalApps')" :subheading="$t('settings.lang_externalApps')"
                    :icon=icon :show-add-button="$store.getters['permissions/checkPermission']('add_external_apps')" :permission-add="$store.getters['permissions/checkPermission']('add_external_apps')"></page-title>
        <div class="app-main__inner">
          <ExternalAPPSComponent/>
        </div>
    </div>
</template>

<script>
    import PageTitle from "@/Layout/Components/PageTitle.vue";
    import ExternalAPPSComponent
      from "../../../../components/settings/moduleSettings/externalapss/ExternalAPPSComponent";

    export default {
        components: {
          ExternalAPPSComponent,
            PageTitle,
        },

        data: () => ({
            icon: 'pe-7s-plane icon-gradient bg-tempting-azure',
        })
    }
</script>